import { TableIds } from "../../../common/table-identifier-utils"
import {
    checkboxColDefCreator,
    costCodeColDefCreator,
    dateColDefCreator,
    editableTimeCardColDefCreator,
    employeeColDefCreator,
    hoursFromMinutesColDefCreator,
    hoursMinutesColDefCreator,
    lengthLimitedColDefCreator,
    signatureActionsColDefCreator,
    timekeepingStatusColDefCreator,
} from "./standard-col-def-creators"
import { dateAndDefaultTimeValueSetter } from "../../../common/ag-grid-value-setters"
import {
    addEmployeeRowsTableAction,
    addRowTableAction,
    alertCellClassRule,
    editTimecardDetailsAction,
    getAvailableStatusValues,
    isGroupRow,
    timekeepingDataIsEditable,
    timekeepingRowIsEditable,
} from "../../../common/ag-grid-utils"
import GroupRowFormattingDefaultCellRenderer from "../cell-renderers/GroupRowFormattingDefaultCellRenderer"
import { createModifierColDefs } from "../../../tk-modifiers/utils"
import { totalHoursValueGetter } from "../../../common/ag-grid-value-getters"
import {
    customSignatureTooltip,
    timekeepingCommentTooltip,
    timekeepingEntryGeneralErrorsTooltip,
    timekeepingEntrySignatureActionsTooltip,
} from "../../../common/ag-grid-custom-tooltip-formatters"
import { getFlagEnabled } from "../../../getFlagValue"
import AttachmentsRenderer from "../cell-renderers/attachments-cell-renderer"
import SignatureRenderer from "../cell-renderers/signature-renderer"
import { compareGroupColumns } from "../../../common/ag-grid-comparators"
import { validateTimekeepingEntries } from "../../../common/validators"
import { getObjectId, lockedPeriodCellClassRule } from "../../../common/ag-grid-ts-utils"
import { getSelectorActionButtons } from "./action-button-settings"
import { referenceablesToValueFormatters } from "../../../common/referenceable-value-formatters"
import { toggleCicoExceptions, toggleHoursExceptions, togglePlaceholdersVisible } from "../../../actions"

const timekeepingEntriesDefaultColDef = {
    cellClassRules: {
        "period-locked": lockedPeriodCellClassRule,
        readonly: params => !timekeepingDataIsEditable(params).canEdit,
        "alert-cell": alertCellClassRule,
    },
    editable: params => timekeepingDataIsEditable(params).canEdit,
    filter: false,
    customTooltip: timekeepingEntryGeneralErrorsTooltip,
}

export const getTimekeepingEntryTable = (
    featureFlags,
    currentProject,
    user,
    currentProjectIds,
    sourceData,
    equipmentRequired = false
) => {
    const userRole = user ? user.user_role : undefined
    const features = user ? user.features : {}
    const groupByTableAction = {
        label: "Group By",
        action: "groupBy",
        icon: "group",
    }
    return {
        tableName: "Timekeeping",
        id: TableIds.TimekeepingModal,
        resources: ["timekeepingEntries"],
        colDefs: [
            {
                ...dateColDefCreator({}),
                valueSetter: dateAndDefaultTimeValueSetter,
            },
            {
                ...checkboxColDefCreator({}),
                checkboxSelection: p => !isGroupRow(p),
            },
            {
                ...employeeColDefCreator({
                    cellRenderer: GroupRowFormattingDefaultCellRenderer,
                    includeCico: featureFlags.cico,
                    isDesignSystem: featureFlags.cico,
                }),
                extraFilterQueryParams: ["cico_start_date", "cico_end_date"],
                ignoreRelatedFilters: ["projectId"],
            },
            {
                ...costCodeColDefCreator({
                    cellRenderer: GroupRowFormattingDefaultCellRenderer,
                    titleFormatter: referenceablesToValueFormatters.costCodes.titleAndStatusFormatter,
                }),
            },
            {
                ...editableTimeCardColDefCreator({
                    headerName: "Time Card",
                    required: true,
                    width: 300,
                }),
                ignoreRelatedFilters: [],
                extraFilterQueryParams: ["start_date", "foreman_id", "project"],
                extraSearchFilters: { allow_empty: true },
                actionOptions: [getSelectorActionButtons().createNewTimecardButton],
            },
            ...(featureFlags.cost_code_level_modifiers
                ? createModifierColDefs(
                      user.company_tk_modifiers ?? [],
                      sourceData?.sourceData,
                      timekeepingEntriesDefaultColDef,
                      equipmentRequired
                  )
                : []),
            ...(getFlagEnabled("WA-8479-time-display-change")
                ? [
                      {
                          ...hoursMinutesColDefCreator({
                              headerName: "ST",
                              field: "/adjusted_minutes_st",
                              cellRenderer: GroupRowFormattingDefaultCellRenderer,
                          }),
                          aggFunc: "sum",
                      },
                      {
                          ...hoursMinutesColDefCreator({
                              headerName: "OT",
                              field: "/adjusted_minutes_ot",
                              cellRenderer: GroupRowFormattingDefaultCellRenderer,
                          }),
                          aggFunc: "sum",
                      },
                      {
                          ...hoursMinutesColDefCreator({
                              headerName: "DT",
                              field: "/adjusted_minutes_dt",
                              cellRenderer: GroupRowFormattingDefaultCellRenderer,
                          }),
                          aggFunc: "sum",
                      },
                  ]
                : [
                      {
                          ...hoursFromMinutesColDefCreator({
                              headerName: "ST",
                              field: "/adjusted_minutes_st",
                              cellRenderer: GroupRowFormattingDefaultCellRenderer,
                          }),
                          aggFunc: "sum",
                      },
                      {
                          ...hoursFromMinutesColDefCreator({
                              headerName: "OT",
                              field: "/adjusted_minutes_ot",
                              cellRenderer: GroupRowFormattingDefaultCellRenderer,
                          }),
                          aggFunc: "sum",
                      },
                      {
                          ...hoursFromMinutesColDefCreator({
                              headerName: "DT",
                              field: "/adjusted_minutes_dt",
                              cellRenderer: GroupRowFormattingDefaultCellRenderer,
                          }),
                          aggFunc: "sum",
                      },
                  ]),
            {
                ...hoursFromMinutesColDefCreator({
                    headerName: "Total",
                    cellRenderer: GroupRowFormattingDefaultCellRenderer,
                }),
                valueGetter: totalHoursValueGetter,
                aggFunc: "sum",
            },
            ...(featureFlags.timekeeping_comments
                ? [
                      {
                          ...lengthLimitedColDefCreator({
                              headerName: "Comment",
                              field: "/comment",
                              maxLength: user.company_options.tkcomment_max_length,
                          }),
                          customTooltip: timekeepingCommentTooltip,
                      },
                  ]
                : []),
            {
                ...timekeepingStatusColDefCreator({
                    enum: getAvailableStatusValues(userRole),
                }),
                cellRenderer: GroupRowFormattingDefaultCellRenderer,
                aggFunc: "statusColumnAggFunc",
            },
            ...(getFlagEnabled("WA-5644-tk-signatures")
                ? [
                      {
                          headerName: "Signature",
                          field: "/signature",
                          width: 110,
                          editable: false,
                          cellRenderer: AttachmentsRenderer("Signature"),
                          sortable: false,
                          suppressSizeToFit: true,
                      },
                  ]
                : [
                      {
                          headerName: "Signature",
                          field: "/signature",
                          width: 110,
                          editable: false,
                          cellRenderer: SignatureRenderer,
                          customTooltip: customSignatureTooltip,
                          sortable: false,
                          suppressSizeToFit: true,
                      },
                  ]),
            ...(getFlagEnabled("WA-5644-tk-signatures") && !features?.disable_web_signature_flow
                ? [
                      {
                          ...signatureActionsColDefCreator({
                              headerName: "Actions",
                              field: "/signature",
                              shouldPreventActionFocus: true,
                          }),
                          customTooltip: timekeepingEntrySignatureActionsTooltip,
                      },
                  ]
                : []),
        ],
        gridSettings: {
            defaultColDef: timekeepingEntriesDefaultColDef,
            autoGroupColumnDef: {
                headerName: "Employee",
                suppressMenu: true,
                sort: "asc",
                unSortIcon: true,
                cellRendererParams: {
                    suppressCount: true,
                },
                comparator: compareGroupColumns,
                checkboxSelection: p => isGroupRow(p),
                groupSelectsChildren: false,
                minWidth: 250,
            },
            enableRangeSelection: true,
            rowSelection: "multiple",
            suppressRowClickSelection: true,
            suppressMultiRangeSelection: true,
            isRowSelectable: rowNode => timekeepingRowIsEditable(rowNode),
        },
        otherSettings: {
            enableClipboard: true,
            pasteRangeEnabled: true,
            filterByLockedColumns: true,
            highlightErrorRows: true,
            hideLockedColumns: true,
            hideCheckboxInHistory: true,
            sizeColumnsToFitSize: 915,
            isInModalTab: true,
            rowLevelValidators: [validateTimekeepingEntries],
            rowLevelErrorDisplay: true,
            suppressExportButton: true,
            exceptionFilters: ["cicoExceptions", "hoursExceptions"],
            // Although the settings specifies possible groupBy options for the
            // Timekeeping tab of the modal, these options are configured on the fly based on
            // the grouping that's in place on the main table when you open the modal.
            // That configuration is handled in the groupingAllowedInCurrentConfiguration function
            // in toolbar/event-handlers/utils.ts
            groupBy: [
                {
                    cols: [],
                    label: "None",
                },
                {
                    cols: ["/employee"],
                    label: "Employee",
                },
                {
                    cols: ["/cost_code"],
                    label: "Cost Code",
                },
            ],
            buttons: {
                cell: [],
                row: [
                    {
                        label: "Delete",
                        icon: "delete",
                        action: "deleteSelectedRows",
                        args: {
                            local_state_update: true,
                        },
                    },
                    ...editTimecardDetailsAction(),
                    ...(featureFlags.cico
                        ? [
                              {
                                  label: "View CI/CO",
                                  icon: "view",
                                  action: "openCicoDetailsForSelectedRows",
                                  disabled: context =>
                                      context.selectedRows.reduce(
                                          (agg, row) => agg.add(getObjectId(row.employee)),
                                          new Set()
                                      ).size !== 1,
                                  tooltip: context => {
                                      if (
                                          context.selectedRows.reduce(
                                              (agg, row) => agg.add(getObjectId(row.employee)),
                                              new Set()
                                          ).size !== 1
                                      )
                                          return "You may only view the CI/CO data of one employee at a time"
                                      else return "View Clock-In/Clock-Out entries for this employee"
                                  },
                              },
                          ]
                        : []),
                ],
                groupRow: [
                    {
                        label: "Add Row",
                        icon: "add",
                        action: "addNewRowToGroup",
                    },
                ],
                table: [
                    groupByTableAction,
                    addRowTableAction(featureFlags),
                    addEmployeeRowsTableAction,

                    {
                        label: "Add Cost Codes",
                        action: "addCostCodeRows",
                        icon: "costCode",
                        disabled: context => context.groupKeyInfo?.find(key => key.colDef.field === "/cost_code"),
                        tooltip: context =>
                            context.groupKeyInfo?.find(key => key.colDef.field === "/cost_code")
                                ? "The entries you are viewing are for a specific " +
                                  "cost code. New cost codes cannot be added to this view"
                                : undefined,
                    },
                    ...(!featureFlags.cico
                        ? [
                              {
                                  label: "Show Placeholders",
                                  action: "togglePlaceholders",
                                  icon: "view",
                                  customButtonType: "placeholders",
                              },
                          ]
                        : []),
                    ...editTimecardDetailsAction(),
                    ...(featureFlags.cico
                        ? [
                              {
                                  label: "Show/Hide ▾",
                                  icon: "view",
                                  args: {
                                      extraArgs: {
                                          options: [
                                              {
                                                  label: "Placeholders",
                                                  action: togglePlaceholdersVisible,
                                                  // The state parameter we look at to see if this should be
                                                  // checked on
                                                  activeStateParams: ["placeholdersVisible"],
                                              },
                                          ],
                                      },
                                  },
                                  checklist: true,
                              },
                          ]
                        : []),
                    ...(featureFlags.cico
                        ? [
                              {
                                  label: "View CI/CO",
                                  icon: "view",
                                  action: "openCicoDetails",
                                  disabled: (ctx, sourceData) => {
                                      const employeeLockedColumn = ctx.groupKeyInfo.find(
                                          gki => gki.colDef.field === "/employee"
                                      )
                                      return (
                                          !employeeLockedColumn &&
                                          sourceData.timekeepingEntries.reduce(
                                              (agg, row) => agg.add(getObjectId(row.employee)),
                                              new Set()
                                          ).size !== 1
                                      )
                                  },
                                  tooltip: (ctx, sourceData) => {
                                      const employeeLockedColumn = ctx.groupKeyInfo.find(
                                          gki => gki.colDef.field === "/employee"
                                      )
                                      if (
                                          !employeeLockedColumn &&
                                          sourceData.timekeepingEntries.reduce(
                                              (agg, row) => agg.add(getObjectId(row.employee)),
                                              new Set()
                                          ).size !== 1
                                      )
                                          return "Please select one or more rows representing a single employee"
                                      else return "View Clock-In/Clock-Out entries for this employee"
                                  },
                              },
                              {
                                  label: "Exceptions ▾",
                                  icon: "exception",
                                  args: {
                                      extraArgs: {
                                          options: [
                                              {
                                                  label: "Exceptions",
                                                  action: () => {
                                                      return dispatch => {
                                                          dispatch(toggleCicoExceptions())
                                                          dispatch(toggleHoursExceptions())
                                                      }
                                                  },
                                                  activeStateParams: ["cicoExceptions", "hoursExceptions"],
                                                  hasGear: false,
                                              },
                                              {
                                                  label: "CI/CO",
                                                  action: context => {
                                                      const columns = context.columnApi.getAllGridColumns()
                                                      // The filter won't fire if we don't do the next step
                                                      context.gridApi.setFilterModel({
                                                          [columns[0].getId()]: {},
                                                      })
                                                      return dispatch => dispatch(toggleCicoExceptions())
                                                  },
                                                  activeStateParams: ["cicoExceptions"],
                                                  hasGear: true,
                                                  gearModalType: "cicoException",
                                                  isChild: true,
                                              },
                                              {
                                                  label: "Irregular Hours",
                                                  action: context => {
                                                      const columns = context.columnApi.getAllGridColumns()
                                                      context.gridApi.setFilterModel({
                                                          [columns[0].getId()]: {},
                                                      })
                                                      return dispatch => dispatch(toggleHoursExceptions())
                                                  },
                                                  activeStateParams: ["hoursExceptions"],
                                                  hasGear: true,
                                                  gearModalType: "hoursException",
                                                  isChild: true,
                                              },
                                          ],
                                      },
                                  },
                                  checklist: true,
                              },
                          ]
                        : []),
                ],
            },
        },
    }
}
